<template>
  <div>
    <v-card
      v-if="!procesing"
      class="mx-auto mt-5 d-flex align-center flex-column"
    >
      <v-card-title class="pb-b"><h4>Text</h4></v-card-title>
      <v-card-text>
        <div class="d-flex align-center justify-center flex-column">
          <v-row class="d-flex justify-center flex-column text-center w-10">
            <v-col>
              <v-textarea outlined counter v-model="text"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group row v-model="record_lang">
                <v-radio label="English" name="lang" value="en"></v-radio>
                <v-radio label="Portuguese" name="lang" value="pt"></v-radio>
                <v-radio label="Other" name="lang" value="ot"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row class="d-flex align-center justify-center flex-column">
            <v-col>
              <v-btn
                rounded
                color="primary"
                :disabled="!text"
                @click="processText"
              >
                <v-icon left>fa-project-diagram</v-icon>
                Graph me
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <!--
      <v-card-actions>
        <router-link :to="{ name: 'record' }" class="card-footer-item"
          >Or make a recording</router-link
        >
      </v-card-actions>
      -->
    </v-card>
    <Processing
      v-if="procesing"
      title="Psychomeasuring!"
      message="Please, be patient."
      icon="fa-cog"
    />
  </div>
</template>
<script>
import { mapState } from "vuex"
import Processing from "@/components/Processing.vue"

export default {
  name: "TextResults",
  components: { Processing },
  computed: {
    text: {
      get() {
        return this.$store.state.speechgraph.text
      },
      set(value) {
        this.$store.commit("speechgraph/text", value)
      },
    },
    record_lang: {
      get() {
        return this.$store.state.speechgraph.lang
      },
      set(value) {
        this.$store.commit("speechgraph/changeLang", value)
      },
    },
    ...mapState({ procesing: state => state.speechgraph.procesing }),
  },
  filters: {
    nl2br: function (value) {
      if (!value) return ""
      value = value.toString()
      return value.replaceAll("\n", "<br>")
    },
  },
  mounted() {
    this.$store.commit("speechgraph/procesing", false)
  },
  methods: {
    processText() {
      this.$store.dispatch("speechgraph/cognitiveAnalysisText", {
        lang: this.record_lang,
        text: this.text,
      })
    },
  },
}
</script>
